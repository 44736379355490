<template>
    <div class="transfer-info">
        <b-button class="mb-3 col-auto print" variant="light" @click="$router.go(-1)">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <div class="d-flex align-items-center mb-4">
            <div v-if="transfer" class="transfer-info__number">
                Operation {{ transfer.transaction.transaction_id }}
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12" :class="'col-lg-6'">
                <ui-card v-if="transfer" title="Transfer details">
                    <ui-list>
                        <ui-list-item title="Transaction ID:">
                            <template #text>
                                <ui-link :to="{ name: 'transactions-info', query: { id: transfer.transaction.id } }">
                                    {{ transfer.transaction.transaction_id }}
                                </ui-link>
                            </template>
                        </ui-list-item>
                        <ui-list-item title="amount:">
                            <template #text>
                                <div class="fw-bold">
                                    <transaction-amount :type="transfer.transaction.type" :amount="transfer.amount" :currency="transfer.currency" />
                                </div>
                            </template>
                        </ui-list-item>
                        <ui-list-item title="Folio:">
                            <template #text>
                                {{ transfer.folio }}
                            </template>
                        </ui-list-item>
                        <ui-list-item title="Numeric reference:" :text="transfer.numeric_reference" />
                        <ui-list-item v-if="transfer.concept && transfer.concept !== ''" title="Concept:" :text="transfer.concept" />
                        <ui-list-item title="Status:">
                            <template #text>
                                <ui-status :status="transfer.status" />
                            </template>
                        </ui-list-item>
                        <ui-list-item title="Created at:">
                            <template #text>
                                {{ $dayjs(transfer.created_at).format('L') }} <br />
                                {{ $dayjs(transfer.created_at).format('LTS') }}
                            </template>
                        </ui-list-item>
                    </ui-list>
                </ui-card>
            </div>
            <div v-if="transfer" :class="'col-lg-6'">
                <ui-card title="Customer Information">
                    <ui-list>
                        <ui-list-item title="Name:" :text="transfer.ordering_name" />
                        <ui-list-item title="Account:" :text="transfer.ordering_account" />
                        <ui-list-item title="Bank:" :text="transfer.ordering_bank" />
                    </ui-list>
                </ui-card>
                <ui-card title="Beneficiary Information">
                    <ui-list>
                        <ui-list-item title="Name:" :text="transfer.beneficiary_name" />
                        <ui-list-item title="CLABE:" :text="transfer.beneficiary_account" />
                        <ui-list-item title="Bank:" :text="transfer.beneficiary_bank" />
                        <ui-list-item title="Tax ID:" :text="transfer.beneficiary_tax_id" />
                        <ui-list-item title="Company:" :text="transfer.company" />
                    </ui-list>
                </ui-card>
            </div>
        </div>
    </div>
</template>

<script>
import UiCard from '@/components/ui/Card';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import TransactionAmount from '@/components/ui/TransactionAmount.vue';
import UiLink from '@/components/ui/Link';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiStatus,
        UiLink,
        TransactionAmount,
    },

    data() {
        return {
            transfer: null,
            transfer_id: this.$route.query.id,
        };
    },
    mounted() {
        this.getTransfer();
    },
    methods: {
        async getTransfer() {
            try {
                const { data } = await this.$axios.get(`/_/transfers/${this.transfer_id}`);
                this.transfer = data;
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss">
.transfer-info {
    &__number {
        font-size: 0.95rem;
    }
}
</style>
